//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductHead from "~/components/loading-placeholders/product-list-ph.vue";
import BannerTypeProduct from "~/components/content-elements/banner-type-product";
import productBox from "~/components/product-boxes/style-1";
import triplepl from "~/components/loading-placeholders/triple-pl.vue";
import ProductPresentationHead2 from "~/components/loading-placeholders/product-presentation-head-pl2.vue";
export default {
  data() {
    return {};
  },
  async asyncData({ store }) {
    await store.dispatch("tea-products/getTeaRange", {
      teaRangeName: "Fun Flavoured Ceylon Tea",
    });
    const pageData = store.state["tea-products"].teaRangeName;
    return { pageData };
  },
  head() {
    return {
      title: this.pageData.cmsData.page.seo.meta_title,
      meta: [
        {
          name: "description",
          hid: "description",
          content: this.pageData.cmsData.page.seo.meta_description,
        },
        {
          name: "og:description",
          hid: "og:description",
          content: this.pageData.cmsData.page.seo.meta_description,
        },
        {
          name: "keywords",
          hid: "keywords",
          content: this.pageData.cmsData.page.seo.keywords,
        },
        {
          property: "og:url",
          content: `https://www.dilmahtea.com${this.$route.path}`,
        },

        {
          property: "og:image",
          hid: "og:image",
          content: this.pageData.cmsData.page.seo.share_image_url,
        },
        {
          property: "og:title",
          hid: "og:title",
          content: this.pageData.cmsData.page.seo.meta_title,
        },
        {
          property: "og:image:alt",
          hid: "og:image:alt",
          content: this.pageData.cmsData.page.seo.share_image_alt,
        },

        {
          name: "shareImageAlt",
          hid: "shareImage",
          content: this.pageData.cmsData.page.seo.share_image_alt,
        },
        {
          name: "shareImage",
          hid: "shareImage",
          content: this.pageData.cmsData.page.seo.share_image_url,
        },
        {
          name: "preventindex",
          hid: "preventindex",
          content: this.pageData.cmsData.page.seo.prevent_indexing,
        },
      ],
    };
  },
  components: {
    productBox,
    BannerTypeProduct,
    triplepl,
    ProductHead,
    ProductPresentationHead2,
  },

  computed: {
    products() {
      return this.pageData.dmcData.products.map((product) => ({
        image: product.product_images[0],
        name: product.product_name,
        description: product.one_line_caption,
        id: product.product_name,
        shopLink: product.online_shop,
      }));
    },
  },
  methods: {
    setIcon(text) {
      let reText = text.replace(/[^a-zA-Z ]/g, "");
      let trimText = reText.trim();
      return `icon-${trimText.split(" ").join("-")}`;
    },
  },

  watch: {
    pageData() {
      this.$nuxt.$loading.finish();
    },
  },
};
